import React from 'react'
import Swiper from 'react-id-swiper'
// import { Link } from 'gatsby'

// import { GatsbyImage } from 'gatsby-plugin-image'
// import paodealho from '../../../images/receitas/pao-de-alho.jpg'
// import canoaRecheada from '../../../images/receitas/canoa-recheada.jpg'
// import molhoFrango from '../../../images/receitas/3-molhos.jpg'
// import milkShake from '../../../images/receitas/milkshake.jpg'
// import cocadaAmendoim from '../../../images/receitas/cocada-amendoim.jpg'
// import petitGateau from '../../../images/receitas/petit-gateau.jpg'

import acompanhamento from '../../../images/receitas/acompanhamentos.png'
// import carne from '../../../images/receitas/carne.png'
import salgado from '../../../images/receitas/salgado.png'
import sobremesa from '../../../images/receitas/sobremesa.png'
import drink from '../../../images/receitas/drinks.png'

import jsonReceitas from '../../../utils/jsonreceitas.json'

/* STYLES */
import * as styles from './sectionReceitasPosts.module.scss'

const SectionReceitasPosts = () => {
  const swiperParams = {
    slidesPerView: 1.3,
    spaceBetween: 0,
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    breakpoints: {
      1000: {
        slidesPerView: 4,
        spaceBetween: 0
      },
      1100: {
        slidesPerView: 4,
        spaceBetween: 1
      },
      1200: {
        slidesPerView: 4,
        spaceBetween: 1
      }
    }
  }

  function imagem (receitaImagem) {
    const a = {
      // paodealho: paodealho,
      // canoaRecheada: canoaRecheada,
      // molhoFrango: molhoFrango,
      // milkShake: milkShake,
      // cocadaAmendoim: cocadaAmendoim,
      // petitGateau: petitGateau,
      acompanhamento: acompanhamento,
      drink: drink,
      salgado: salgado,
      sobremesa: sobremesa
    }

    return a[receitaImagem]
  }

  return (
    <section className='container my-5'>
      <h2 className="text-center text-red" tabIndex="0">
        Receitas do DIA
      </h2>
      <div className='receitaPostsMobile'>
        <Swiper {...swiperParams}>
          {/* {data.receitas.edges.map((rec, index) => (
            <div className='px-3 pt-4 pb-0 pb-sm-4' key={index}>
              <Link to={`/receitas/${rec.node.categoriaSlug}/${rec.node.slug}/`} className="text-dark">
                <div className={`d-flex flex-column align-items-start ${styles.customCard}`}>
                  <div className='d-flex flex-column align-items-start justify-content-end w-100' style={{ maxHeight: '202px', minHeight: '202px' }}>
                    <GatsbyImage
                      image={rec.node.image.localFile.childImageSharp.gatsbyImageData}
                      className='w-100'
                      alt="Imagem da Receita" />
                    <span className={`position-absolute font-size-17 text-white bg-dia-blue rounded mx-4 px-2 py-0 mb-3 ${styles.customBorder}`}>{rec.node.categoria}</span>
                  </div>
                  <div className='bottom-border-card w-100 py-2 px-4'>
                    <p className='font-size-15 limit-text'><span>{rec.node.title}</span></p>
                  </div>
                </div>
              </Link>
            </div>
          ))} */}

          {jsonReceitas.map((receitas, index) => {
            const a = imagem(receitas.imagem)
            return (
              <div className='px-3 pt-4 pb-0 pb-sm-4' key={index} >
                <a href={`${receitas.categoriaLink}`} className="text-dark" target="_blank" rel="noreferrer">
                  {/* <a href='https://tododiareceitas.com.br/' target="_blank" className="text-dark" rel="noreferrer"> */}
                  <div className={`d-flex flex-column align-items-start ${styles.customCard}`}>
                    <div className='d-flex flex-column align-items-start justify-content-end w-100' style={{ maxHeight: '302px', minHeight: '202px' }}>
                      <img src={a}
                        className='w-100'
                        alt="Imagem da Receita" />
                      {/* <span className={`position-absolute font-size-17 text-white bg-dia-blue rounded mx-4 px-2 py-0 mb-3 ${styles.customBorder}`}>{receitas.categoria}</span> */}
                    </div>
                    {/* <div className='bottom-border-card w-100 py-2 px-4'>
                      <p className='font-size-15 limit-text'><span>{receitas.title}</span></p>
                    </div> */}
                  </div>
                  {/* </a> */}
                </a>
              </div>
            )
          })}
        </Swiper>
      </div>
    </section >
  )
}
export default SectionReceitasPosts
